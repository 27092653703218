export const LOGIN_URL = 'login/';
export const BASE_URL = window.location.protocol + "//" + window.location.hostname + ":8090/";
export const API_URL = `${BASE_URL}api/v1/`;
export const PRODUCTS_URL = 'products/';
export const REQUESTS_URL = 'requests/';
export const SET_PASSWORD_URL = 'auth/set-password/';
export const CUSTOMERSS_URL = 'admin/customers/';
export const GENERATE_NEW_USER = 'auth/generate-new-user/';
export const FULFILLMENT_CENTERS_URL = 'admin/fulfillment-centers/';
export const SHOPIFY_COLLECTIONS_URL = 'admin/smart_collections/';
export const USERS_URL = 'admin/users/';
export const INVOICE_URL = 'invoices/';
export const DOWNLOAD_INVOICE_URL = 'download-invoice/';
export const PRODUCT_STATS_URL = 'product-stats/';
export const ORDERS_STATS_URL = 'orders-stats/';
export const CONDITION_AGGS_URL = 'condition-aggs/';
export const RESET_PASSWORD_URL = 'reset-password/';
