import PropTypes from 'prop-types';
import styled from 'styled-components';

const CommonButtonWrapper = styled.button`
  display: inline-block;
  padding: 5px;
  background-color: #A687B4;
  cursor: pointer;
  color: white;
  border-radius: 5px;
  border: 0.5px solid #a0a0a0;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  &:hover{
    background-color: #b9a3c3;
  }
  &.disabled{
    background-color: grey;
  }
`;

const CommonButton = (props) => {
  return (<CommonButtonWrapper className={props.classes} onClick={props.onClick}>
    {props.children}
  </CommonButtonWrapper>);
}

CommonButton.propTypes = {
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  classes: PropTypes.string
};

export default CommonButton;
