// ProductsList.js
import React, { useEffect, useState } from 'react';
// import './ProductsList.css';
import styled from 'styled-components'
import { getRequest } from '../util/request';
import { REQUESTS_URL } from '../common/urls';
import LoadingOverlay from '../common/LoadingOverlay';

const RequestsListTile = styled.div`
  position: relative;
  overflow: hidden;
  .requests-list{
    max-height: 450px;
    overflow: auto;
    max-width: 450px;
    width: 500px;
    height: 400px;
    
    .request-row{
      display: flex;
      flex-direction: row;
      padding: 10px 10px 0 10px;
      align-items: center;
      color: gray;

      &:nth-child(even){
        background-color: #fafafa;
      }
      &:nth-child(odd){
        background-color: #effaf1;
      }
      
      .request-detail{
        text-align: left;
        min-height: 60px;

        .request-detail-title{
          font-size: 10px;
          font-weight: normal;
          margin-bottom: 5px;
        }

        &.request-detail-date{
          flex: 2;
        }
        &.request-detail-request-number{
          flex: 2;
        }
        &.request-detail-transaction-number{
          flex: 2;
        }
        &.request-detail-item-count{
          flex: 1;
        }
      }
    }
    .no-requests-message{
      display: flex;
      height: 100%;
      align-items: center;
      align-content: center;
      justify-content: center;
    }
  }
`

function RequestsList(props) {
  const [requests, setRequests] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const adminViewAs = props.adminViewAs;
  const isShowAsCustomer = props.showAsCustomer;
  useEffect(() => {
    setIsLoading(true);
    getRequest(
      `${REQUESTS_URL}${isShowAsCustomer ? "?customerId="+adminViewAs : ""}`,
      (response) => {setRequests(response); setIsLoading(false)},
      () => {setIsLoading(false)}
    )    
  },[isShowAsCustomer])
  return (
    <RequestsListTile className="dashboard-tile products-dashboard">
      <h3>Inbound Arrival</h3>
      <div className="requests-list">
        {
          requests.length > 0 ? 
          requests.map((request, index) => {
            var quantityOfItems = request['inventory_count'];
            return (
              <div className="request-row" key={index}>
                <div className="request-detail request-detail-date">
                  <div className="request-detail-title">Processing Date</div>
                  <div className="request-detail-value">{new Date(request['created']).toDateString()}</div>
                </div>
                {request['request_number'] ? 
                  <div className="request-detail request-detail-request-number">
                    <div className="request-detail-title">Inbound Number</div>
                    <div className="request-detail-value">{request['request_number']}</div>
                  </div>
                :""}
                <div className="request-detail request-detail-transaction-number">
                  <div className="request-detail-title">Transaction Number</div>
                  <div className="request-detail-value">{request['transaction_number'] ? request['transaction_number'] : request.request['transaction_number']}</div>
                </div>
                <div className="request-detail request-detail-item-count">
                  <div className="request-detail-title">Item Count</div>
                  <div className="request-detail-value">{quantityOfItems}</div>
                </div>
              </div>
            )
          })
          :
          <div className="no-requests-message">No inbound requests available yet.</div>
        }
        <LoadingOverlay isLoading={isLoading}/>
      </div>
    </RequestsListTile>
  );
}

export default RequestsList;
