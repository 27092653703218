import styled from 'styled-components';
import { connect, useDispatch } from "react-redux";
import tildi_logo from '../../../static/tildi_logo.png'
import Dashboard from '../Dashboard';
import { Navigate, Route, Routes, useLocation } from "react-router";
import Inventory from '../Inventory';
import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import AuthService from '../../common/AuthService';
import LoginScreen from '../LoginScreen';
import { initialState } from "./MainScreenReducer";
import { ToastContainer, toast } from 'react-toastify';
import UserCustomerDetailsWrapper from '../UserCustomerDetails'
import { detailsFetched, getCustomers } from './MainScreenActions';
import AdminScreen from '../AdminScreen/AdminScreen'
import ActivateAccountScreen from '../ActivateAccountScreen';
import { ReactComponent as HomeIcon } from '../../../static/tildi-home-icon.svg';
import { ReactComponent as InventoryIcon } from '../../../static/tildi-inventory-icon.svg';
import { ReactComponent as ProfileIcon } from '../../../static/tildi-profile-icon.svg';
import { ReactComponent as BurgerMenuIcon } from '../../../static/tildi-burger-menu-icon.svg';
import { ReactComponent as BackIcon } from '../../../static/tildi-back-icon.svg';
import Sales from '../Sales';
import ForgotPasswordScreen from '../ForgotPasswordScreen';

const MainScreenWrapper = styled.div`
    position: relative;
    display: flex;
    .left-bar-nav{
        height: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        .left-bar-nav-item{
            display: flex;
            text-decoration: none;
            font-size: 18px;
            font-weight: bold;
            cursor: pointer;
            color: black;
            width: 100%;
            padding: 15px 0;
            text-align: left;
            &.selected{
                color: #56805B;
                background-color: #F3F8F7;
                .nav-button-icon{
                    path{
                        fill: #56805B;
                    }
                }
                .nav-button-icon-stroke{
                    path{
                        stroke: #56805B;
                    }
                }
            }
            .nav-button-text{
                margin-left: 15px;
            }
            .nav-button-icon, .nav-button-icon-stroke{
                margin-left: 30px;
            }
        }
    }
`
const LeftBar = styled.div`
    position: relative;
    float: left;
    display: flex;
    min-width: 220px;
    background-color: white;
    box-shadow: -1px 0px 9px 1px #797979;
    flex-direction: column;
    align-content: center;
    align-items: center;
    height: calc(100vh);
    user-select: none;
    .logo-wrapper{
        margin-top: 50px;
        position: relative;
        .tildi-logo{
            width: 100px;
        }
        .logo-text{
            position: absolute;
            top: 54px;
            font-size: 12px;
            left: -2px;
        }
    }
    .customer-logo-wrapper{
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 20px;
        position: relative;
        .customer-logo{
            max-height: 50px;
            max-width: 120px;
        }
        .remove-view-as-button{
            position: absolute;
            right: 40px;
            font-weight: bold;
            cursor: pointer;
            color: red;
            text-decoration: underline;
        }
    }
    .view-as-link{
        text-decoration: underline;
    }
`
const RightWindow = styled.div`
    // width: calc(100vw - 30px - 250px);
    height: 100vh;
    overflow: auto;
    padding: 0 30px;
    width: 100%;
`
const ViewAsDialogOverlay = styled.div`
    visibility: ${props => props.show};
    position: absolute;
    height: calc(100vh);
    width: calc(100vw);
    background-color: #00000042;
    top: 0;
    .view-as-dialog{
        // position: absolute;
        width: 600px;
        height: 300px;
        background-color: white;
        margin: 100px auto;
        border-radius: 10px;
        padding: 10px 30px;
    }
`

const TopBar = styled.div`
    display: flex;
    border-radius: 0 0 10px 10px;
    padding: 20px;
    background-color: white;
    box-shadow: 0px 0px 2px 0px #797979;
    user-select: none;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    .logo-wrapper{
        position: relative;
        .tildi-logo{
            width: 100px;
        }
        .logo-text{
            position: absolute;
            top: 54px;
            font-size: 12px;
            left: -2px;
        }
    }
    .customer-logo-wrapper{
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 20px;
        position: relative;
        .customer-logo{
            max-height: 50px;
            max-width: 120px;
        }
        .remove-view-as-button{
            position: absolute;
            right: 40px;
            font-weight: bold;
            cursor: pointer;
            color: red;
            text-decoration: underline;
        }
    }
    .view-as-link{
        text-decoration: underline;
    }
`
const OverlayLeftBar = styled.div`
    top: 0;
    right: 0;
    position: absolute;
    display: flex;
    min-width: 220px;
    background-color: white;
    box-shadow: -1px 0px 9px 1px #797979;
    flex-direction: column;
    align-content: center;
    align-items: center;
    height: calc(100vh);
    user-select: none;
    transition: 0.5s right;
    z-index: 99;
    &.hidden{
        right: -500px;
    }
    .hide-vertical-bar-button{
        cursor: pointer;
        position: absolute;
        left: -15px;
        top: 10px;
        height: 20px;
        width: 20px;
        border-radius: 30px;
        background-color: white;
        border: 1px solid #d0d0d0;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(180deg);
        svg{
            height: 15px;
        }
    }
`

export const MAIN_SCREEN_STORE_KEY = "MainScreen";
const OVERVIEW_SCREEN = "overview";
const INVENTORY_SCREEN = "inventory";
const SALES_SCREEN = "sales";
const ADMIN_SCREEN = "admin";
const FORGOT_PASSWORD_SCREEN = "forgot-password";
const VIEW_AS_KEY = 'viewAs';

const AuthRoute = ({ children }) => {
    const isLoggedIn = AuthService.isLoggedIn();

    return isLoggedIn ? children : <Navigate to="/login" />;
}

const AdminRoute = ({ children, user }) => {
    // const isLoggedIn = AuthService.isLoggedIn();
    const isAdminUser = user && user.is_superuser;
    return isAdminUser ? children : <Navigate to="/" />;
}

const NonAuthRoute = ({ children }) => {
    const isLoggedIn = AuthService.isLoggedIn();

    return isLoggedIn ? <Navigate to="/" /> : children;
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

const MainScreen = (state) => {
    const dispatch = useDispatch();
    const isLoggedIn = AuthService.isLoggedIn();
    const [adminViewAs, setAdminViewAs] = useState("");
    const [showViewAsDialog, setShowViewAsDialog] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [showVerticalLeftBar, setShowVerticalLeftBar] = useState(false);
    const path = useLocation();
    var viewAs = searchParams.get(VIEW_AS_KEY)
    var currentPath = "";
    if (path.pathname.length > 1) {
        currentPath = path.pathname.split("/")[1];
    } else {
        currentPath = OVERVIEW_SCREEN;
    }
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
          }
          window.addEventListener('resize', handleResize);
          return () => window.removeEventListener('resize', handleResize);
    },[]);
    useEffect(() => {
        setShowVerticalLeftBar(false);
    },[path])
    useEffect(() => {
        if (AuthService.isLoggedIn()) {
            AuthService.getUserInfo((oCurrentUserInfo) => {
                if (oCurrentUserInfo['is_superuser']) {
                    getCustomers((customersResponse) => {
                        dispatch(detailsFetched(oCurrentUserInfo, [], customersResponse))
                    })
                }
                else {
                    dispatch(detailsFetched(oCurrentUserInfo, [], state.customers))
                }
            });
        }
    }, [state.loginAttempt.currentToken])
    useEffect(() => {
        if(viewAs){
            setAdminViewAs(viewAs);
        } else {
            setAdminViewAs("");
        }
    },[viewAs]);
    const removeViewAs = () => {
        searchParams.delete(VIEW_AS_KEY);
        setSearchParams(searchParams);
    }
    var showAsCustomer = adminViewAs != "";
    var isVertical = windowDimensions.width < windowDimensions.height;
    var tildiLogoSection = (<div className="logo-wrapper">
        <img className="tildi-logo" src={tildi_logo} />
    </div>);
    var customerLogoSection = (<div className="customer-logo-wrapper">
        {state.currentUser && state.currentUser.customer ?
            <img className="customer-logo" src={state.currentUser.customer.icon} />
            : ""}
        {
            state.currentUser && state.currentUser['is_superuser'] ?
                showAsCustomer ?
                    <><img className="customer-logo" src={state.customers.find((customer) => { return customer.id == adminViewAs }).icon} /> <div onClick={removeViewAs} className="remove-view-as-button">x</div></>
                    :
                    <div className="view-as-link" onClick={() => { setShowViewAsDialog(true) }}>View As</div>
                :
                ""
        }
    </div>);
    var navSection = (<nav className="left-bar-nav">
        <Link  to={{pathname:`/${OVERVIEW_SCREEN}`,search:path.search}} className={`left-bar-nav-item ${currentPath == OVERVIEW_SCREEN ? ' selected' : ''}`}><HomeIcon className="nav-button-icon" /><span className="nav-button-text">Overview</span></Link>
        <Link to={{pathname:`/${INVENTORY_SCREEN}`,search:path.search}} className={`left-bar-nav-item ${currentPath == INVENTORY_SCREEN ? ' selected' : ''}`}><InventoryIcon className="nav-button-icon" /><span className="nav-button-text">Inventory</span></Link>
        {
            state.currentUser && state.currentUser['is_superuser'] ?
                <Link to={{pathname:`/${ADMIN_SCREEN}`,search:path.search}} className={`left-bar-nav-item ${currentPath == ADMIN_SCREEN ? ' selected' : ''}`}><ProfileIcon className="nav-button-icon-stroke" /><span className="nav-button-text">Admin</span></Link>
                :
                ""
        }
    </nav>);
    return (
        <MainScreenWrapper>
            {
                isLoggedIn && <OverlayLeftBar className={`${showVerticalLeftBar ? '' : 'hidden'}`}>
                    <div onClick={() => {setShowVerticalLeftBar(false)}} className="hide-vertical-bar-button"><BackIcon/></div>
                    {navSection}
                    {state.currentUser ? <UserCustomerDetailsWrapper user={state.currentUser} /> : ""}
                </OverlayLeftBar>
            }
            {isLoggedIn && !isVertical &&
                <LeftBar>
                    {tildiLogoSection}
                    {customerLogoSection}
                    {navSection}
                    {state.currentUser ? <UserCustomerDetailsWrapper user={state.currentUser} /> : ""}
                </LeftBar>
            }
            <RightWindow>
                {
                    isLoggedIn && isVertical && 
                    <TopBar>
                        <div>
                            {tildiLogoSection}
                            {customerLogoSection}
                        </div>
                         <div style={{cursor:'pointer'}} onClick={() => {setShowVerticalLeftBar(!showVerticalLeftBar)}}>
                            <BurgerMenuIcon/>
                        </div>
                        
                    </TopBar>
                }
                <Routes>
                    <Route path="/login" element={<NonAuthRoute><LoginScreen /></NonAuthRoute>} />
                    <Route path="/activate-account/:uid64/:token" element={<NonAuthRoute><ActivateAccountScreen /></NonAuthRoute>} />
                    <Route index element={<AuthRoute>
                        <Dashboard
                            adminViewAs={adminViewAs}
                            showAsCustomer={showAsCustomer}
                            user={state.currentUser}
                        />
                    </AuthRoute>} />
                    <Route path={`/${OVERVIEW_SCREEN}`} element={
                        <AuthRoute>
                            <Dashboard
                                adminViewAs={adminViewAs}
                                showAsCustomer={showAsCustomer}
                                user={state.currentUser}
                            />
                        </AuthRoute>
                    } />
                    <Route path={`/${INVENTORY_SCREEN}`} element={
                        <AuthRoute>
                            <Inventory
                                adminViewAs={adminViewAs}
                                showAsCustomer={showAsCustomer}
                                user={state.currentUser}
                            />
                        </AuthRoute>
                    } />
                    <Route path={`/${SALES_SCREEN}`} element={
                        <AuthRoute>
                            <Sales
                                adminViewAs={adminViewAs}
                                showAsCustomer={showAsCustomer}
                                user={state.currentUser}
                            />
                        </AuthRoute>
                    } />
                    <Route path={`/${ADMIN_SCREEN}`} element={
                        <AdminRoute showAsCustomer={showAsCustomer} user={state.currentUser}>
                            <AdminScreen />
                        </AdminRoute>
                    } />
                    <Route path={`/${FORGOT_PASSWORD_SCREEN}`} element={
                        <NonAuthRoute>
                            <ForgotPasswordScreen />
                        </NonAuthRoute>
                    } />
                </Routes>
            </RightWindow>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                theme="light"
            />
            <ViewAsDialogOverlay show={showViewAsDialog ? 'visible' : 'hidden'}>
                <div className="view-as-dialog">
                    <h2>View As</h2>
                    <form>
                        <select className="customer-field field-input">
                            <option disabled selected value={-1}>-- Select Customer To View As --</option>
                            {state.customers.map((customer) => {
                                return (<option value={customer.id}>{customer.name}</option>);
                            })}
                        </select>
                        <button 
                            onClick={(e) => { 
                                setShowViewAsDialog(false);
                                const customerId = e.target.form[0].value
                                if(customerId != -1){
                                    searchParams.set(VIEW_AS_KEY, customerId);
                                    setSearchParams(searchParams);
                                }
                                e.preventDefault(); 
                            }} className="customer-view-as-button">View As Customer</button>
                    </form>
                </div>

            </ViewAsDialogOverlay>
        </MainScreenWrapper>
    )
}

const mapStateToProps = (state) => {
    const componentState = state[MAIN_SCREEN_STORE_KEY];
    return componentState ? componentState : initialState;
}

export default connect(mapStateToProps)(MainScreen);

